import { Button } from 'commons/components'
import React from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { CgChevronLeft } from 'react-icons/cg'

import ModifiedFormTambahActivity from '../components/ModifiedFormTambahActivity'

const TambahActivityPage = props => {
  return (
    <div>
      <Link to={`/activity`} className="no-underline">
        <Button
          className="absolute z-10 top-24 left-8 hidden md:block"
          variant="secondary"
        >
          Kembali
        </Button>
        <div className="flex md:hidden items-center text-primary gap-1 mt-6 ml-6 -mb-3 font-medium">
          <CgChevronLeft size={24} />
          Kembali
        </div>
      </Link>

      <ModifiedFormTambahActivity {...props} />
    </div>
  )
}

export default TambahActivityPage
