import { Button } from 'commons/components'
import React, { useEffect, useState } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { CgChevronLeft } from 'react-icons/cg'

import getPrograms from '../services/getPrograms'
import getChartOfAccounts from '../services/getChartOfAccounts'

import ModifiedFormTambahPengeluaran from '../components/ModifiedFormTambahPengeluaran'

const TambahPengeluaranPage = props => {
  const [programs, setPrograms] = useState()
  const [chartOfAccounts, setChartOfAccounts] = useState()

  useEffect(() => {
    const fetch = async () => {
      const { data: programsResponse } = await getPrograms()
      const { data: chartOfAccountsResponse } = await getChartOfAccounts()

      setPrograms(programsResponse.data)
      setChartOfAccounts(chartOfAccountsResponse.data)
    }
    fetch()
  }, [])

  return programs && chartOfAccounts ? (
    <div>
      <Link to={`/expense`} className="no-underline">
        <Button
          className="absolute z-10 top-24 left-8 hidden md:block"
          variant="secondary"
        >
          Kembali
        </Button>
        <div className="flex md:hidden items-center text-primary gap-1 mt-6 ml-6 -mb-3 font-medium">
          <CgChevronLeft size={24} />
          Kembali
        </div>
      </Link>

      <ModifiedFormTambahPengeluaran
        {...{ programs, chartOfAccounts }}
        {...props}
      />
    </div>
  ) : (
    <></>
  )
}

export default TambahPengeluaranPage
