import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { useAuth } from 'commons/auth'
import { Button, TableRow, TableCell, Modal } from 'commons/components'
import { BsThreeDotsVertical } from 'react-icons/bs'

const UserTable = ({ userItem, index }) => {
  const { checkPermission } = useAuth()

  return (
    <TableRow distinct={false}>
      {/* Data Binding User Table Element*/}
      <TableCell>{userItem?.name}</TableCell>
      <TableCell>{userItem?.email}</TableCell>
      <TableCell className={'p-0 sm:p-4'}>
        <>
          <div class="hidden sm:btn-group gap-2">
            {/* View Element Event User Table Element*/}
            <Link to={`/settings/user/${userItem.id}`}>
              <Button variant="primary">Detail</Button>
            </Link>
          </div>
          <div
            className={`sm:hidden flex justify-center dropdown dropdown-end w-5 ${
              index < 1 ? 'dropdown-bottom' : 'dropdown-left'
            }`}
          >
            <button tabIndex={0}>
              <BsThreeDotsVertical className="w-5 h-5 text-base-content" />
            </button>
            <ul
              tabIndex={0}
              className="dropdown-content menu p-1 shadow-md bg-base-100 w-40 mr-4 rounded-box"
            >
              <li onClick={() => document.activeElement.blur()}>
                <Link to={`/settings/user/${userItem.id}`}>
                  <button>Detail</button>
                </Link>
              </li>
            </ul>
          </div>
        </>
      </TableCell>
    </TableRow>
  )
}

export default UserTable
