import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { useAuth } from 'commons/auth'
import { Button, TableRow, TableCell, Modal } from 'commons/components'
import { BsThreeDotsVertical } from 'react-icons/bs'

import deleteIncome from '../services/deleteIncome.js'

const PemasukanTable = ({ pemasukanItem, index }) => {
  const { checkPermission } = useAuth()

  const [
    showModalKonfirmasiHapusPemasukan,
    setShowModalKonfirmasiHapusPemasukan,
  ] = React.useState(false)

  const hapus = async pemasukan => {
    await deleteIncome({
      id: pemasukan.id,
    })
    window.location.reload(false)
  }

  return (
    <TableRow distinct={false}>
      {/* Data Binding Pemasukan Table Element*/}
      <TableCell isHiddenMobile>{pemasukanItem?.datestamp}</TableCell>
      <TableCell>{pemasukanItem?.programName}</TableCell>
      <TableCell isCurrency>{pemasukanItem?.amount}</TableCell>
      <TableCell isHiddenMobile>{pemasukanItem?.paymentMethod}</TableCell>
      <TableCell className={'p-0 sm:p-4'}>
        <>
          <div class="hidden sm:btn-group gap-2">
            {/* View Element Event Pemasukan Table Element*/}
            <Link to={`/income/${pemasukanItem.id}`}>
              <Button variant="primary">Detail</Button>
            </Link>

            {checkPermission('UpdateIncome') && (
              <Link to={`/income/ubah?id=${pemasukanItem.id}`}>
                <Button variant="secondary">Ubah</Button>
              </Link>
            )}

            {checkPermission('DeleteIncome') && (
              <Link to="">
                <Button
                  variant="tertiary"
                  onClick={() => setShowModalKonfirmasiHapusPemasukan(true)}
                >
                  Hapus
                </Button>
              </Link>
            )}
          </div>
          <div
            className={`sm:hidden flex justify-center dropdown dropdown-end w-5 ${
              index < 1 ? 'dropdown-bottom' : 'dropdown-left'
            }`}
          >
            <button tabIndex={0}>
              <BsThreeDotsVertical className="w-5 h-5 text-base-content" />
            </button>
            <ul
              tabIndex={0}
              className="dropdown-content menu p-1 shadow-md bg-base-100 w-40 mr-4 rounded-box"
            >
              <li onClick={() => document.activeElement.blur()}>
                <Link to={`/income/${pemasukanItem.id}`}>
                  <button>Detail</button>
                </Link>
              </li>

              {checkPermission('UpdateIncome') && (
                <li onClick={() => document.activeElement.blur()}>
                  <Link to={`/income/ubah?id=${pemasukanItem.id}`}>
                    <button>Ubah</button>
                  </Link>
                </li>
              )}

              {checkPermission('DeleteIncome') && (
                <li onClick={() => document.activeElement.blur()}>
                  <Link to="">
                    <button
                      onClick={() => setShowModalKonfirmasiHapusPemasukan(true)}
                    >
                      Hapus
                    </button>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </>
      </TableCell>
      <Modal isShow={showModalKonfirmasiHapusPemasukan}>
        <Link to="">
          <Button
            variant="tertiary"
            onClick={() => setShowModalKonfirmasiHapusPemasukan(false)}
          >
            Batal
          </Button>
        </Link>
        <Link to="">
          <Button variant="danger" onClick={() => hapus(pemasukanItem)}>
            Hapus
          </Button>
        </Link>
      </Modal>
    </TableRow>
  )
}

export default PemasukanTable
