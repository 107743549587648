import { Button } from 'commons/components'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import DetailRole from '../components/DetailRole'
import getRoleDetail from '../services/getRoleDetail'
import { CgChevronLeft } from 'react-icons/cg'

const DetailRolePage = props => {
  const [role, setRole] = useState()
  const { id } = useParams()

  useEffect(() => {
    const fetchData = async () => {
      const { data: roleDetail } = await getRoleDetail({ id })
      setRole(roleDetail.data)
    }
    fetchData()
  }, [])

  return role ? (
    <div className="prose max-w-screen-lg mx-auto p-6">
      <Link to={`/settings/role`} className="no-underline">
        <Button
          className="absolute z-10 top-24 left-8 hidden md:block"
          variant="secondary"
        >
          Kembali
        </Button>
        <div className="flex md:hidden items-center text-primary gap-1 -mb-10">
          <CgChevronLeft size={24} />
          Kembali
        </div>
      </Link>

      <h2>Detail Role</h2>
      <DetailRole {...{ role }} />
    </div>
  ) : (
    <></>
  )
}

export default DetailRolePage
