import { Button } from 'commons/components'
import React, { useEffect, useState } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { CgChevronLeft } from 'react-icons/cg'

import getUser from '../services/getUser'
import getAllowedPermissions from '../services/getAllowedPermissions'

import FormUbahUser from '../components/FormUbahUser'

const UbahUserPage = props => {
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')

  const [user, setUser] = useState()
  const [allowedPermissions, setAllowedPermissions] = useState()

  useEffect(() => {
    const fetch = async () => {
      const { data: userResponse } = await getUser({ id })
      const { data: allowedPermissionsResponse } = await getAllowedPermissions()

      setUser(userResponse.data)
      setAllowedPermissions(allowedPermissionsResponse.data)
    }
    fetch()
  }, [])

  return user && allowedPermissions ? (
    <div>
      <Link to={`/settings/user/${id}`} className="no-underline">
        <Button
          className="absolute z-10 top-24 left-8 hidden md:block"
          variant="secondary"
        >
          Kembali
        </Button>
        <div className="flex md:hidden items-center text-primary gap-1 mt-6 ml-6 -mb-3 font-medium">
          <CgChevronLeft size={24} />
          Kembali
        </div>
      </Link>

      <FormUbahUser {...{ user, allowedPermissions }} {...props} />
    </div>
  ) : (
    <></>
  )
}

export default UbahUserPage
