import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { useAuth } from 'commons/auth'
import { Button, TableRow, TableCell, Modal } from 'commons/components'
import { BsThreeDotsVertical } from 'react-icons/bs'

import deleteExpense from '../services/deleteExpense.js'

const PengeluaranTable = ({ pengeluaranItem, index }) => {
  const { checkPermission } = useAuth()

  const [
    showModalKonfirmasiHapusPengeluaran,
    setShowModalKonfirmasiHapusPengeluaran,
  ] = React.useState(false)

  const hapus = async pengeluaran => {
    await deleteExpense({
      id: pengeluaran.id,
    })
    window.location.reload(false)
  }

  return (
    <TableRow distinct={false}>
      {/* Data Binding Pengeluaran Table Element*/}
      <TableCell isHiddenMobile>{pengeluaranItem?.datestamp}</TableCell>
      <TableCell>{pengeluaranItem?.programName}</TableCell>
      <TableCell isCurrency>{pengeluaranItem?.amount}</TableCell>
      <TableCell isHiddenMobile>{pengeluaranItem?.coaName}</TableCell>
      <TableCell className={'p-0 sm:p-4'}>
        <>
          <div class="hidden sm:btn-group gap-2">
            {/* View Element Event Pengeluaran Table Element*/}
            <Link to={`/expense/${pengeluaranItem.id}`}>
              <Button variant="primary">Detail</Button>
            </Link>

            {checkPermission('UpdateExpense') && (
              <Link to={`/expense/ubah?id=${pengeluaranItem.id}`}>
                <Button variant="secondary">Ubah</Button>
              </Link>
            )}

            {checkPermission('DeleteExpense') && (
              <Link to="">
                <Button
                  variant="tertiary"
                  onClick={() => setShowModalKonfirmasiHapusPengeluaran(true)}
                >
                  Hapus
                </Button>
              </Link>
            )}
          </div>
          <div
            className={`sm:hidden flex justify-center dropdown dropdown-end w-5 ${
              index < 1 ? 'dropdown-bottom' : 'dropdown-left'
            }`}
          >
            <button tabIndex={0}>
              <BsThreeDotsVertical className="w-5 h-5 text-base-content" />
            </button>
            <ul
              tabIndex={0}
              className="dropdown-content menu p-1 shadow-md bg-base-100 w-40 mr-4 rounded-box"
            >
              <li onClick={() => document.activeElement.blur()}>
                <Link to={`/expense/${pengeluaranItem.id}`}>
                  <button>Detail</button>
                </Link>
              </li>

              {checkPermission('UpdateExpense') && (
                <li onClick={() => document.activeElement.blur()}>
                  <Link to={`/expense/ubah?id=${pengeluaranItem.id}`}>
                    <button>Ubah</button>
                  </Link>
                </li>
              )}

              {checkPermission('DeleteExpense') && (
                <li onClick={() => document.activeElement.blur()}>
                  <Link to="">
                    <button
                      onClick={() =>
                        setShowModalKonfirmasiHapusPengeluaran(true)
                      }
                    >
                      Hapus
                    </button>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </>
      </TableCell>
      <Modal isShow={showModalKonfirmasiHapusPengeluaran}>
        <Link to="">
          <Button
            variant="tertiary"
            onClick={() => setShowModalKonfirmasiHapusPengeluaran(false)}
          >
            Batal
          </Button>
        </Link>
        <Link to="">
          <Button variant="danger" onClick={() => hapus(pengeluaranItem)}>
            Hapus
          </Button>
        </Link>
      </Modal>
    </TableRow>
  )
}

export default PengeluaranTable
